<template>
  <div class="second-level">
    <header>
      <div class="sidemenu" @click="$emit('secondLevel','')">
        <div class="sidemenu__btn">
          <span class="top"></span>
          <span class="bottom"></span>
        </div>
      </div>

      关于我们
    </header>
    <div class="content-wrapper">
      <p>
        彩云资讯是一家在互联网构建中国和世界各重要彩票资讯平台的高科技公司，是以彩票资讯业务为核心，兼顾软件研发与网站建设等互联网业务的高效、多元的综合性互联网公司。
      </p>
      <p>
        公司经营网站为”彩云资讯”，网址： www.lottos.cloud.
      </p>
      <p>
        公司由数十位彩票界与IT行业资深人士构成，具备一流的网站设计与研发能力，立足于中国千亿元的彩票市场，与彩票界内数百位彩票分析师合作，共建彩票行业一流的垂直门户。
      </p>
      <p>
        企业使命是：客户享受优越的关怀和服务，员工拥有美好的生活和前景，股东得到稳定的资产增长和回报。
      </p>
      <p>
        公司倡导创新敢为的精神，形成了”诚信、创新、团结、进取”的价值观。公司贯彻绩效奖励淘汰机制，不拘一格选择提拔优秀人才。
      </p>
    </div>
  </div>

</template>

<script>

export default {
  name: 'SecondLevelAbout'
}
</script>
<style scoped lang="scss">
.second-level{
  position:fixed;
  z-index:3;
  width:100%;
  background: #f6f5f5;
  overflow-y:auto;
  height:calc(100% - 80px);
}
header{
  height: 50px;
  width: 100%;
  background-color: #0f2957;
  top:0;
  position:fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}
.content-wrapper{
  padding-top:70px;
  margin:0 15px;
  text-align: left;
}
p{
  font-size: 10px !important;
  line-height: 2 !important;
  margin-bottom: 8px;
  color:#7d7d7d;
}

.sidemenu {
  &__btn {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 50px;
    height: 50px;
    border: none;
    z-index: 100;
    appearance: none;
    cursor: pointer;
    outline: none;

    span {
      display: block;
      width: 12px;
      height: 2px;
      margin: auto;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    left: 0;
    .top {
      transform: rotate(-40deg);
      top: -7px;
    }

    .bottom {
      transform: rotate(40deg);
      top: 7px;
    }


  }
}

</style>

