<template>
    <div class="field">
        <div class="content">
            <a href="/">
                <img src="@/img/_bar1.png">
            </a>
            <a href="https://www.lottery.gov.cn/" target="_blank">
                <img src="@/img/_bar2.png">
            </a>
        </div>
    </div>   
</template>

<script>
    export default {
        
    }
</script>


<style lang="scss" scoped>
img{
    height: 100%;
    cursor: pointer;
}
.field{
    width: 100%;
    height: 5.62rem;
    background-color: #eff8fd;
    .content{
        width: $inner;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        padding: 0.77rem 0;
        box-sizing: border-box;
    }
}
 
</style>