<template>
    <div class="Top">
        <div class="header" :class="`${ currentRouteName=='Home' &&  !navOpen ? '' : 'blue'}`">
            <a href="/" v-if="!navOpen && currentRouteName=='Home'">
                <img class="logo" src="@/img/H5/logo.png">
            </a> 
            <span class="title" v-if="!navOpen && $route.name == 'Lottery'">
                开奖历史
            </span>
            <span class="title" v-if="!navOpen && $route.path == '/mobile/gamerule'">
                游戏规则
            </span>
            <span class="title" v-if="!navOpen && $route.path == '/mobile/site'">
                彩票官网
            </span>
            <span class="title" v-if="!navOpen && $route.path == '/mobile/about'">
                关于我们
            </span>
            <span class="title" v-if="navOpen && $route.path != '/mobile/about'">所有彩种</span>
            <span class="title" v-if="navOpen && $route.path == '/mobile/about'">我的</span>
        </div>
        <div class="sidemenu" :class="`${ currentRouteName=='Home' &&  !navOpen ? '' : 'blue'}`">
            <div class="sidemenu__btn"  v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
				<span class="top"></span>
				<span class="mid"></span>
				<span class="bottom"></span>
			</div>
        </div>
        <transition>
            <HeaderMenu v-show="navOpen"/>
        </transition>

    </div>    
</template>
<script>
import HeaderMenu from '@/components/H5/HeaderMenu.vue'
export default {
      name: 'Top',
      components: {
            HeaderMenu,
        },
      data(){
           return {
               navOpen: false,
               checkShow: true,
           }
      },
      computed:{
        currentRouteName() {
            return this.$route.name;
        }
        
      },
      mounted(){


      }
}
</script>
<style lang="scss" scoped>
.Top {
  width: 100%;
  z-index: 3;
  position: fixed;
  
  .header {
    height: 50px;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &.blue{
       background-color: #0f2957;
       
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      color: #fff;
    }
  }

  .sidemenu {
     &.blue{
            span {
              background: #fff;
            }
      }
    &__btn {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      width: 40px;
      height: 50px;
      border: none;
      z-index: 100;
      appearance: none;
      cursor: pointer;
      outline: none;

     

      span {
        display: block;
        width: 22px;
        height: 2px;
        margin: auto;
        background: #444;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all .4s ease;

        &.top {
          transform: translateY(-5px);
          
        }
        &.bottom {
          transform: translateY(5px);
         
        }
      }

      &.active {
        left: 0;
       
        .top {
          transform: rotate(-40deg);
          top: -8px;
          width: 12px;
          background: #fff;
        }

        .mid {
          transform: translateX(0px) rotate(360deg);
          opacity: 0;
           background: #fff;
        }

        .bottom {
          transform: rotate(40deg);
          top: 5px;
          width: 12px;
           background: #fff;
        }
      }

    }
   

    
  }


}
</style>
   