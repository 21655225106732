
import moment from 'moment-timezone';
import {lotteryConfig} from "@/assets/lotteryConfig.js";

// 秒數轉換成dd:hh:mm:ss
export function formatReciprocal(sec) {
    let day = parseInt(sec/86400);
    let hour = padLeft(parseInt(sec/3600)% 24, 2);
    let minute = padLeft(parseInt(sec/60)% 60, 2);
    let second = padLeft(sec%60, 2);
    return `${day<0 ? 0: day} : ${hour<0 ? '00': hour} : ${minute<0 ? '00': minute} : ${second<0 ? '00': second}`;
}

// 補0
export function padLeft(str,lenght){
    //to string
    str = `${str}`;
    if(str.length >= lenght)
    return str;
    else
    return padLeft("0" +str,lenght);
}

// 即將開講彩種顯示期數
export function formatComingLotteryWithDate(period, drawTime, lotteryCode) {
    let setting = lotteryConfig[lotteryCode];
    let type = setting.displayperiodType;
    let result = "";
    period= period == undefined ? '' : period;
    switch(type) {
        case "nationwide":
            result = `${period} 期`;
            break;
        default:
            result = `${moment(drawTime).format('YYYY-MM-DD')}`;
    }
    return result;
}

export function formatComingLottery(period, drawTime, lotteryCode) {
    let setting = lotteryConfig[lotteryCode];
    let type = setting.displayperiodType;
    let result = "";
    period= period == undefined ? '' : period;
    switch(type) {
        case "nationwide":
            result = `距 ${period} 期开奖`;
            break;
        default:
            result = `距 ${moment(drawTime).format('YYYY-MM-DD')} 开奖`;
    }
    return result;
}

export function formatNewsTime(time) {
    // let newsTime = new Date(time);
    // let nowTime = new Date();
    // //相減後秒
    // let difference = parseInt((nowTime.getTime()-newsTime.getTime())/1000);
    // let result = "";
    // if(difference <　3600) {
    //     result = `${parseInt(difference/60)} 分钟前`
    // } else if (difference < 86400) {
    //     result = `${parseInt(difference/3600)} 小时前`
    // } else {
    //     result = `${parseInt(difference/86400)} 天前`
    // }
    return time;
}

//秒換算

export function reciprocalDay(sec) {
    if(!sec) {
        return 0;
    }
    let day = parseInt(sec/86400);
    return day<0 ? 0: day;
}

export function reciprocalHour(sec) {
    if(!sec) {
        return 0;
    }
    let hour = padLeft(parseInt(sec/3600)% 24, 2);
    return hour<0 ? '00': hour;
}

export function reciprocalMinute(sec) {
    if(!sec) {
        return 0;
    }
    let minute = padLeft(parseInt(sec/60)% 60, 2);
    return minute<0 ? '00': minute;
}

export function reciprocalSecond(sec) {
    if(!sec) {
        return 0;
    }
    let second = padLeft(parseInt(sec%60), 2);
    return second<0 ? '00': second;
}

//期號後綴
export function periodSuffix(period, lotteryCode, drawTime) {
    let setting = lotteryConfig[lotteryCode];
    let type = setting.displayperiodType;
    let result = "";
    period= period == undefined ? '' : period;
    switch(type) {
        case "nationwide":
            result = `${period} 期`;
            break;
        default:
            result = `${moment(drawTime).format('YYYY-MM-DD')}`;
    }
    return result;
}

//日期 有中文字
export function formateDrawTimeDate(drawTime) {
    if(!drawTime) {
        return "";
    }
    return moment(drawTime).format('YYYY年MM月DD日');
}
//日期 有中文字
export function formateDrawTimeDateMD(drawTime) {
    if(!drawTime) {
        return "";
    }
    return moment(drawTime).format('MM月DD日');
}

//日期 顯示YYYY-MM-DD
export function formateDrawTimeDateDash(drawTime) {
    return moment(drawTime).format('YYYY-MM-DD');
}
//日期 顯示YYYY-MM-DD hh::mm
export function formateDrawTimeAll(drawTime) {
    return moment(drawTime).format('YYYY-MM-DD HH:mm:ss');
}
//日期 顯示hh::mm::ss
export function formateDrawTime(drawTime) {
    return moment(drawTime).format('HH:mm:ss');
}
//home lottery card
export function formatComingLotteryType2(period, drawTime, lotteryCode) {
    let setting = lotteryConfig[lotteryCode];
    let type = setting.displayperiodType;
    let result = "";
    period= period == undefined ? '' : period;
    switch(type) {
        case "nationwide":
            result = `第 ${period} 期`;
            break;
        case "satlotto":
            result = `第 ${period} 期`;
            break;
        default:
            result = `${moment(drawTime).format('YYYY-MM-DD')}`;
    }
    
    return result ;
}

//home lottery card
export function checkDisplayPeriod(period, lotteryCode,numType) {
    let setting = lotteryConfig[lotteryCode];
    let type = setting.displayperiodType;
    let result = "";
    if(!period || period == undefined) {
        return ''; 
    }
    if(numType){
        result = `${period}`;
        
    }else{
        switch(type) {
            case "nationwide":
                result = `第 ${period} 期`;
                break;
            default:
                result = ``;
        }
    }
    return result;
}

export function splitDrawNumber(numbers,code){
    // 英国极速快乐8和英国快乐8,前20顆球由小到大排序
    if(code!=null && code=="cannon20ex" || code=="cannon20"){

        let tempNum = [...numbers];
        let lastNum=tempNum.pop();
        let drawArray=tempNum.sort();
        
        drawArray.push(lastNum);
        return drawArray.map((num)=>num.split('-')).flat();
    }else{
        return numbers.map((num)=>num.split('-')).flat();
    }
    
}

export function drawsFilter(string){
    let blueArray=['单','小','虎','尾小','金钱','葫芦','半顺','杂六','单多','前多','火','木','水','金','土','总小单','总小双','通吃'];
    let redArray=['双','大','龙','尾大','鸡','鱼','顺子','双多','总大单','后多','总大双'];
    let greenArray=['蟹','虾','前后和','单双和','和'];
    if( blueArray.includes(string)){
        return 'color-blue'
    }else if( redArray.includes(string)){
        return 'color-red'
    } else if( greenArray.includes(string)){
        return 'color-green'
    }else{
        return 'color-red'
    }
}