<template>
    <div class="home">
        <div class="content">
            <div class="left">
                <LotteryHomeCard v-for="(item, key, index) in homeLotteryConfig" :lotteryCode="key" v-if="readMoreCount-1 >= index" :key="key" @openVedio="openVedio"/>
               
            </div>
            <div class="right">
                <a target="_blank" href="https://www.lottery.gov.cn/plwf/index.html">
                    <div class="banner">
                        <img src="@/img/home_banner1.png">
                    </div>
                </a>
                <a target="_blank" href="https://bet.hkjc.com/marksix/index.aspx?lang=ch">
                    <div class="banner">
                        <img src="@/img/side-banner_2.jpg">
                    </div>
                </a>
            </div>
        </div>

        <div class="read_more_row" >
            <div :class="`read_more ${readMoreCount < Object.keys(homeLotteryConfig).length ? '': 'noActive'}`" @click="addReadMoreCount" >
                <img src="@/assets/svg/read_more.svg">
            </div>
             <div :class="`read_more ${readMoreCount == 3 ? 'noActive' : ''}`" @click="removeReadMoreCount" >
                <img class="transY" src="@/assets/svg/read_more.svg">
            </div>
        </div>
      
        <div class="ad">
            <Advertisement />
        </div>
        
        <div v-if="showVedio">
            <pcVideoLive @closeVedio="closeVedio" :lotteryType="clickVedioLotteryType" :lotteryInfo="clickVedioLotteryinfo"></pcVideoLive>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import LotteryHomeCard from "@/components/lottery_home_card/Lottery_Home_Card.vue";
import Advertisement from "@/components/Advertisement.vue";
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import pcVideoLive from "@/components/live_stream/pcVideoLive";

export default {
    name: 'Home',
    components: {
        LotteryHomeCard,
        Advertisement,
        pcVideoLive,
    },
    data() {
        return {
            readMoreCount: 3,
            homeLotteryConfig: lotteryConfig,
            currentHistoryName:'',
            showVedio: false,
            clickVedioLotteryType:'',
            clickVedioLotteryinfo:'',
        }
    },
    computed: {
        ...mapState([
            "lotteryInfo"
        ]), 
    },
    methods: {
        addReadMoreCount () {
            this.readMoreCount+=3;
        },
        removeReadMoreCount(){
            this.readMoreCount-=3;
            if(this.readMoreCount<=0){
                this.readMoreCount=3;
            }

        },
        openVedio(lotteryCode, lotteryInfo){
            this.clickVedioLotteryType = lotteryCode;
            this.clickVedioLotteryinfo = lotteryInfo;
            this.showVedio = true;
        },
        closeVedio(){
            this.showVedio = false;
        },
    },
    watch: {
        '$route': {
            handler: function(to, from) {
               this.currentHistoryName = to.params.name;
               this._lotteryUtil.changeLottery();
            },
            immediate: true
        } 
    },
    mounted() {
       
    },
}
</script>

<style scoped lang="scss">
.home {
    .content {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .right{
            margin-left: 232px;
            .banner{
                width: 2.42rem;
                height: 5rem;
                margin-bottom: 0.32rem;
                img{
                    width: 100%;
                }
            }
        }
    }
    .read_more_row{
        margin-bottom: 0.8rem;
        display: inline-flex;
        .read_more{
            width: 0.6rem;
            height: 0.6rem;
            margin: auto;
            cursor: pointer;
            pointer-events:inherit;
            margin: 0 10px;
            img{
                width: 100%;
            }
            &.noActive{
                filter: grayscale(1);
                pointer-events: none;
            }
        }

    }
    .transY{
        transform: rotateX(180deg); 
    }
}    
</style>