<template>
  <div class="info_table" v-if="display.length > 0">
    <table>
      <tbody>
        <tr>
          <td
            v-for="(item, index) in animalArr"
            :key="index"
            :style="`width:${lotteryTableConfig[lotteryCode].bodyWidth}`"
          >
            {{ item }}
          </td>
        </tr>
        <tr>
          <td
            class="element"
            v-for="(item, index) in elementArr"
            :key="index"
            :style="`width:${lotteryTableConfig[lotteryCode].bodyWidth}`"
          >
            {{ item }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    display: Array,
    lotteryCode: String,
  },
  data() {
    return {
      lotteryTableHeader: {
        qxc: [
          ["第一位", "2"],
          ["第二位", "2"],
          ["第三位", "2"],
          ["第四位", "2"],
          ["第五位", "2"],
          ["第六位", "2"],
          ["第七位", "2"],
        ],
        pl5: [
          ["万位", "2"],
          ["仟位", "2"],
          ["佰位", "2"],
          ["拾位", "2"],
          ["个位", "2"],
        ],
        powerball: [
          ["奇偶", "1"],
          ["和值", "1"],
          ["平均值", "1"],
          ["连号", "1"],
          ["首尾差", "1"],
          ["尾数和", "1"],
        ],
        megamillions: [
          ["奇偶", "1"],
          ["和值", "1"],
          ["平均值", "1"],
          ["连号", "1"],
          ["首尾差", "1"],
          ["尾数和", "1"],
        ],
        pl3: [
          ["佰拾和", "3"],
          ["佰个和", "3"],
          ["拾个和", "3"],
          ["拾个和", "3"],
        ],
      },
      lotteryTableConfig: {
        qxc: {
          headWidth: "105px",
          bodyWidth: "51px",
        },
        pl5: {
          headWidth: "105px",
          bodyWidth: "51px",
        },
        powerball: {
          headWidth: "121px",
          bodyWidth: "121px",
        },
        megamillions: {
          headWidth: "121px",
          bodyWidth: "121px",
        },
        pl3: {
          headWidth: "183px",
          bodyWidth: "59px",
        },
        xglhc: {
          headWidth: "105px",
          bodyWidth: "105px",
        },
        kl8lhc: {
          headWidth: "105px",
          bodyWidth: "105px",
        },
        sgplhc: {
          headWidth: "105px",
          bodyWidth: "105px",
        },
      },
      animalArr: [],
      elementArr: [],
    };
  },
  watch: {
    display: {
      handler: function(value) {
        if (value.length >= 14) {
          this.setArr(value);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setArr(data) {
      //備份值
      let newData = Object.assign([], data);

      this.animalArr = newData.splice(0, 7);
      this.elementArr = newData;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.info_table {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #7e7e7e;
  padding: unset;
  table-layout: fixed;

  table{
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  }
  th {
    height: 0.5rem;
    background-color: #f0ebd0;
  }
  tr:nth-child(odd) td {
    background-color: #f0ebd0;
  }
  tr:nth-child(even) td {
    background-color: #fff;
  }
    tr{
        border: 1px solid #e0d6a7;
    }
  td {
    border: 1px solid #e0d6a7;
    height: 50px;
  }
  
    /*第一欄第一列：左上*/
    tr:first-child td:first-child{
    border-top-left-radius: 5px;
    }
    /*第一欄最後列：左下*/
    tr:last-child td:first-child{
    border-bottom-left-radius: 5px;
    }
    /*最後欄第一列：右上*/
    tr:first-child td:last-child{
    border-top-right-radius: 5px;
    }
    /*最後欄第一列：右下*/
    tr:last-child td:last-child{
    border-bottom-right-radius: 5px;
    }
  .element {
    color: #df0d15;
  }
}
</style>
