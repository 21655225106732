<template>
    <div class="Footer">
        <div class="footer_content">
            <div class="row" @click="$emit('secondLevel','')">
                <router-link class="item" active-class="active" exact  to="/mobile" >
                     <p class="title">首页</p>
                </router-link>
                <router-link class="item" active-class="active" :class="setUseActive(this.currentHistoryName)" to="/mobile/lottery/xglhc">
                    <p class="title">开奖历史</p>
                </router-link>
                 <router-link class="item" active-class="active" exact  to="/mobile/gamerule">
                     <p class="title">游戏规则</p>
                </router-link>
                <router-link class="item" active-class="active" exact  to="/mobile/site">
                     <p class="title">彩票官网</p>
                </router-link>
                 <router-link class="item" active-class="active" exact  to="/mobile/about" >
                     <p class="title">我的</p>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import {lotteryConfig} from "@/assets/lotteryConfig.js";
export default {
    data(){
        return{
            currentHistoryName:'',
            lotteryConfig: lotteryConfig,
        }
    },
    watch: {
        '$route': {
            handler: function(to, from) {
               this.currentHistoryName = to.params.name;
            },
        } 
    },
    methods:{
        setUseActive(string){
            if(string == undefined || lotteryConfig[string] == undefined) return;
            return 'active';
        },
    },
}
</script>
<style lang="scss" scoped>
    .Footer{
        position: fixed;
        width: 100%;
        border-top: 1px solid #c3c2c2;
        background-color:#f0efef ;
        bottom: 0;
        -webkit-box-shadow: 0px -2px 14px -3px rgba(0,0,0,0.48); 
        box-shadow: 0px -2px 14px -3px rgba(0,0,0,0.48);
        z-index: 2;
        .footer_content{
            
            .row{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-content: center;
                .item{
                    color: #727272;
                    flex: 1;
                    height: 62px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    .title{
                        width: 100%;
                        color: #727272;
                        font-size: 12px;
                        
                    }
                   &:before{
                       content: "";
                       display: block;
                       width: 100%;
                       height: 25px;
                    }

                    &.active{
                        .title{
                            color: #0f2957;
                            font-size: 12px;
                         }
                        &:nth-child(1):before{
                        background: url("~@/img/H5/home-2.png") no-repeat;
                        background-size: auto;
                        background-position-x: center;
                        }
                        &:nth-child(2):before{
                            background: url("~@/img/H5/statistics-2.png") no-repeat;
                            background-size: auto;
                            background-position-x: center;
                        }
                        &:nth-child(3):before{
                            background: url("~@/img/H5/games-2.png") no-repeat;
                            background-size: auto;
                            background-position-x: center;
                        }
                        &:nth-child(4):before{
                            background: url("~@/img/H5/global-2.png") no-repeat;
                            background-size: auto;
                            background-position-x: center;
                        }
                        &:nth-child(5):before{
                            background: url("~@/img/H5/user-2.png") no-repeat;
                            background-size: auto;
                            background-position-x: center;
                        }
                    }
                }
                .item:nth-child(1):before{
                    background: url("~@/img/H5/home-1.png") no-repeat;
                    background-size: auto;
                    background-position-x: center;
                }
                .item:nth-child(2):before{
                    background: url("~@/img/H5/statistics-1.png") no-repeat;
                    background-size: auto;
                    background-position-x: center;
                }
                .item:nth-child(3):before{
                    background: url("~@/img/H5/games-1.png") no-repeat;
                    background-size: auto;
                    background-position-x: center;
                }
                .item:nth-child(4):before{
                    background: url("~@/img/H5/global-1.png") no-repeat;
                    background-size: auto;
                    background-position-x: center;
                }
                 .item:nth-child(5):before{
                    background: url("~@/img/H5/user-1.png") no-repeat;
                    background-size: auto;
                    background-position-x: center;
                }
                .item:not(:last-child){

                    border-right: 1px solid #c3c2c2;
                }
            }
        }
    }
</style>