<template>
    <div class="pcVideoLive" @click="closeVedio">
        <div class="modular-2">
            <div class="containers" style="top: 70%;" @click.stop>
                <!-- Loading the player ... -->
                <video-player
                    v-if="isReloadAlive && isLive"
                    id="myplayer" class="video-wrapper vjs-default-skin vjs-big-play-centered"
                    ref="videoPlayer"
                    :options="playerOptions"
                    @statechanged="playerStateChanged($event)"
                    @play="onPlayerPlay($event)">
                </video-player>
                <img v-if="!isLive" style="width: 640px; height:360px;" :src="require('@/img/'+playerOptions.poster)">
                <div class="show-msg" v-if="isReloadAlive">
                    <div style="color: #ff4a3c">{{ lotteryInfo.name }}</div>
                    <div style="color: #e7ef35">第{{ lotteryInfo.next_data.period_no }}期开奖现场</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import {videoPlayer} from 'vue-video-player'
import moment from "moment";
import {getLiveStreamSource} from "@/api/api.js";
import {lotteryConfig} from "@/assets/lotteryConfig.js";

// import lotteryDrawTime from '../../utils/lotteryDrawTime'

export default {
    components: {
        videoPlayer,
    },
    props: ["name", "preDrawIssue", "lotteryType", "lotteryInfo"],
    data: function () {
        return {
            tabsNums: 8,
            next_draw_data: "",
            lotteryConfig: lotteryConfig,
            isReloadAlive: true,
            playerOptions: {
                // playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                width: 640,
                height: 360,
                autoplay: true, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                poster: "zhibo-bg.png", // 你的封面地址
                language: 'zh-CN',
                //aspectRatio: '4:3', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                // sources: [{type: 'application/x-mpegURL',
                //         src: 'https://cdn3.wowza.com/1/bEVJNTMxVnFXc1c5/ZWplUHFl/hls/live/playlist.m3u8'}],
                sources: [],
                //techOrder:  ["flash"],
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        };
    },
    computed: {
        transcoderName() {
            switch (this.lotteryType) {
                // 香港6
                case 'xglhc':
                    return 'HK6_SS';
                // 台灣6
                case 'twdlt':
                    return 'TW6_SS';
                // 英國天天採
                case 'lucky7daily':
                    return 'UK7_SS';
                // 排5和7星
                // case 'qxc':
                // case 'pl5':
                //     return 'P5_SS';
                default:
                    return '';
            }
        },
        isLive() {
            //return true;
            //if (this.lotteryType === 'xglhc' || this.lotteryType === 'lucky7daily' ) {
            if (this.lotteryConfig[this.lotteryType].liveStream) {
            // if (true) {
                //const next_draw_time = moment(this.$store.state.baseInfo[this.lotteryType].next_draw_time);
                const next_draw_time = moment(this.lotteryInfo.next_data.draw_time);//先定義開獎時間
                next_draw_time.startOf('day');//取日
                const liveStartTime = next_draw_time.clone()
                                        .add(this.lotteryConfig[this.lotteryType].liveStreamConfig.liveStartTimeHour, 'hours')
                                        .add(this.lotteryConfig[this.lotteryType].liveStreamConfig.liveStartTimeMin, 'minutes'); //設定當天的21:22
                const liveEndTime = next_draw_time
                                        .add(this.lotteryConfig[this.lotteryType].liveStreamConfig.liveEndTimeHour, 'hours')
                                        .add(this.lotteryConfig[this.lotteryType].liveStreamConfig.liveEndTimeMin, 'minutes');//設定當天的21:45
                // next_draw_time.startOf('day');
                // console.log(next_draw_time.startOf('day').format());
                // console.log(liveStartTime.format());
                // console.log(liveEndTime.format());
                // console.log(moment().isBetween(liveStartTime, liveEndTime, undefined, '[)'));
                return moment().isBetween(liveStartTime, liveEndTime, undefined, '[)');//判定現在時間是否在設定時間之間
            }

            //return lotteryDrawTime.checkLotteryLiveTime(this.lotteryType);
            return false;
        },
    },
    methods: {
        nextDrawTime(datas) {
            this.next_draw_data = datas;
        },
        onPlayerPlay() {
            this.videoIsPlay = true;
        },
        playerStateChanged(state) {
        },
        reload() {
            this.isReloadAlive = false;
            this.$nextTick(() => {
                this.isReloadAlive = true;
            })
        },
        async updateVideoSource() {
            // 去拿直播源網址
            let liveStreamSource = await getLiveStreamSource(`${this.transcoderName}`);
            this.playerOptions.sources.push({
                type: 'application/x-mpegURL',
                src: liveStreamSource
            })
            this.reload();
        },
        closeVedio() {
            this.$emit('closeVedio');
        }
    },
    mounted: function () {
        this.updateVideoSource();
    },
};
</script>
<style lang="scss" scoped>
.pcVideoLive {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    z-index: 99;
    .modular-2 {
        position: relative;
        display: block;
        height: 360px;
        margin-bottom: 10px;

        .containers {
            position: relative;
            margin: 0 auto;
            width: 640px;
            height: 360px !important;
            text-align: center;
            border: none !important;
            background: none !important;

            .show-msg {
                font-family: "微软雅黑";
                display: flex;
                font-size: 18px;
                font-weight: 700px;
                position: absolute;
                z-index: 99;
                top: 40px;
                right: 10px;

                div {
                    margin-right: 20px;
                }
            }

            iframe {
                width: 100%;
            }
        }

    }
}
</style>
