<template>
    <div class="header_menu" v-if="isShow" @mouseleave="hideMenu">
        <div class="menu_content">
            <div class="menu_option">
                <div class="column">
                    <a class="menu_" v-if="!lotteryList[0].subItem"  v-for="(item,index) in lotteryList" :key="index" :href="item.url" :target="RegExp(/http/).test(item.url) ? '_blank': ''" @click="hideMenu">
                        {{item.name}}
                     </a>
                </div>
                <div class="row" v-for="(item,index) in lotteryList" :key="index" v-if="lotteryList[0].subItem" :class="lotteryList.length >1 && index!=lotteryList.length-1 ? 'addline' : ''">
                    <div class="title">{{item.name}}</div>
                    <div v-for="(subItem,_index) in item.subItem" :key="_index">
                        <a :href="subItem.url" :target="RegExp(/http/).test(subItem.url) ? '_blank': ''">{{subItem.name}}</a>
                        <img v-if="subItem.newImg" class="icon" src="@/img/new_img.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'HeaderMenu',
        props: {
            msg: String,
            lotteryList: {
                type: Array,
                default: []
            }
        },
        data() {
        return {
                isShow: true,
            }
        },
        components: {
        },
        methods: {
            showMenu () {
                this.isShow = true;
            },
            hideMenu () {
                this.$emit("hideMenu");
            },
            mouseEventHide () {
                this.$nextTick(() => {
                    this.$emit("hideMenu");
                }); 
            }
        },
        mounted() {      
            document.addEventListener("click", this.mouseEventHide, false);
        },
        destroyed() {
            document.removeEventListener("click", this.mouseEventHide, false);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .header_menu {
        position: absolute;
        z-index: 100;
        height: auto;
        width: auto;
        min-width: 1.5rem;
        border-radius: 6px;
        background-color: rgba(255, 104, 25, 0.9);
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        .menu_content {
            position: relative;
            box-sizing: border-box;
            .menu_option{     
                width: auto;
                height:auto;
                max-height:500px;
                padding: 0.08rem 0;
                .column{
                    display: flex;
                    flex-flow: column;
                    text-align:  center;
                    a{
                        font-size: 16px;
                        color: #ffffff;
                        margin: 7px 0;                                       
                    }
                    a:hover{
                        color: #ffed74;
                        text-decoration: underline;   
                    }
                }
                .row{
                    display: flex;
                    flex-wrap: nowrap;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    margin-left: 36px;
                    .title{
                        font-weight: bold;
                        font-size: 18px;
                        color: #8f3303;
                        text-align: left;
                        margin-right: 44px;
                        width: auto;
                        min-width: 108px;
                    }
                    a{
                        font-size: 16px;
                        color: #ffffff;
                        text-align: left; 
                        margin-right: 18px;
                    }
                    a:hover{
                        color: #ffed74;
                         text-decoration: underline;   
                    }
                    .icon{
                        position: relative;
                        top: -18px;
                        left: -34px;
                        float: right;
                    }
                    .btn{
                        font-size: 18px;
                        font-weight: 300;
                        font-stretch: normal;
                        text-align: left;
                        color: #a0abc1;
                        margin-right: 0.4rem;
                    }
                }
                  .row.addline:before{
                        content: "";
                        display: block;
                        width: calc(100% - 205px);
                        right: 2%;
                        border-bottom: 1px solid #ff9f6d;
                        position: absolute;
                        margin-top: 30px;
                    }
                .menu_item{ 
                    text-align: center;
                    cursor: pointer;                  
                    span{
                        color: #ffffff;
                        font-size: 15px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 0.4rem;
                        letter-spacing: normal;
                    }
                    span:hover{
                        color: #ffe6cc;
                    }
                }
                .menu_item:hover{ 
                    background-color: rgba(253, 145, 65, 1);
                }
                .nopint{
                    pointer-events: none;
                }
            }
        }
        
    }
</style>