import store from '@/store';
import { getlotteryInfo, getSinglelotteryInfo } from '@/api/api.js';
import { lotteryConfig } from '@/assets/lotteryConfig.js';
import moment from 'moment-timezone';
import router from '@/router';

let updatelotteryInfoColdDown = 0;
let updatelotteryInfoRange = 20;

let lotteryDrawTime = null;

let lotteryCodeList = Object.keys(lotteryConfig);
let currentLotteryPage = '';

export function getLotteryStatus(lotteryCode) {
  return store.state.lotteryInfo[lotteryCode];
}

function lotteryInfoHandle(lotteryInfo, currentLottery) {
  let newlotteryDrawTime = {};
  if (Object.keys(lotteryInfo).length > 4) {
    for (let lottery_key in lotteryInfo) {
      let lotteryDrawTime = lotteryInfo[lottery_key].next_data?.draw_time;
      let lotteryServerDrawTime = lotteryInfo[lottery_key].server_time;
      newlotteryDrawTime[lottery_key] = getDrawTiming(
        lotteryDrawTime,
        lotteryServerDrawTime
      );
    }
  } else {
    let lotteryDrawTime = lotteryInfo.next_data.draw_time;
    let lotteryServerDrawTime = lotteryInfo.server_time;
    newlotteryDrawTime[currentLottery] = getDrawTiming(
      lotteryDrawTime,
      lotteryServerDrawTime
    );
  }
  lotteryDrawTime = newlotteryDrawTime;
}
function getDrawTiming(drawTimeString, serverDrawTimeString) {
  // drawTimeString=moment(drawTimeString).tz('UTC+'+localTimeZone).format()
  let drawTime = new Date(checkIOSTime(drawTimeString));
  let nowTime = new Date(checkIOSTime(serverDrawTimeString));
  // 相減後秒
  let difference = parseInt((drawTime.getTime() - nowTime.getTime()) / 1000);
  return difference;
}

function checkIOSTime(time) {
  let userAgent = navigator.userAgent;
  if (userAgent.indexOf('Safari') > -1 && time) {
    time = time.replace(/\-/g, '/');
  }
  return time;
}

function convertTime12to24(time12h) {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}
function parseDate(input) {
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4]); // months are 0-based
}

async function updatelotteryInfo(tempStore, currentLottery) {
  if (updatelotteryInfoColdDown <= 0) {
    let reslotteryInfo = await (currentLottery != null
      ? getSinglelotteryInfo(currentLottery)
      : getlotteryInfo());
    if (reslotteryInfo && reslotteryInfo.data){
        const matchInfo =  Object.keys(reslotteryInfo.data).reduce((object, key) => {
          if (!key.match(/^(sgplhc)/)) {
            object[key] = reslotteryInfo.data[key];
          }
          return object;
        }, {});
        let newlotteryInfo = matchInfo;
        tempStore.state.lotteryInfo = newlotteryInfo;
        lotteryInfoHandle(tempStore.state.lotteryInfo, currentLottery);
    }
  }
}
async function updateBaseInfoForLucky7daily(tempStore, currentLottery) {
  if (currentLottery == 'lucky7daily') {
    let reslotteryInfo = await (currentLottery != null
      ? getSinglelotteryInfo(currentLottery)
      : getlotteryInfo());
    const matchInfo = Object.keys(reslotteryInfo.data).reduce((object, key) => {
      if (!key.match(/^(sgplhc)/)) {
        object[key] = reslotteryInfo.data[key];
      }
      return object;
    }, {});
    let newlotteryInfo = matchInfo;
    tempStore.state.lotteryInfo[currentLottery] = newlotteryInfo;
    lotteryInfoHandle(tempStore.state.lotteryInfo, currentLottery);
  }
}

function checkCountTime() {
  let config = {
    time: '22:00:02',
    duringTime: '22:05:00',
  };

  let nowTime = new Date().getTime();
  let timePoint = config.time.split(':').map((i) => parseInt(i));
  let duringTimePoint = config.duringTime.split(':').map((i) => parseInt(i));
  let returnValue = false;

  let recent = new Date().setHours(...timePoint);
  let recentDuringTime = new Date().setHours(...duringTimePoint);

  if (nowTime >= recent && nowTime <= recentDuringTime) {
    //現在時間小於間格時間, 大於設定時間
    returnValue = true;
  }
  return returnValue;
}

let lotteryUtil = {
  init: async () => {
    let tempStore = store;
    let currentLottery = null;
    if (router.history.current.params.name != undefined) {
      currentLottery = router.history.current.params.name;
    } else {
      currentLottery = null;
    }
    //await updatelotteryInfo(tempStore,currentLottery);
    setInterval(() => {
      if (router.history.current.params.name != undefined) {
        currentLottery = router.history.current.params.name;
      } else {
        currentLottery = null;
      }
      if (!lotteryDrawTime) {
        return;
      }

      lotteryCodeList.forEach((key) => {
        if (!isNaN(lotteryDrawTime[key])) {
          lotteryDrawTime[key] -= 1;
        }
      });

      if (
        lotteryCodeList.find(
          (lotteryCode) =>
            lotteryDrawTime[lotteryCode] <= 0 &&
            !isNaN(lotteryDrawTime[lotteryCode])
        ) &&
        updatelotteryInfoColdDown <= 0
      ) {
        updatelotteryInfo(tempStore, currentLottery);
        updatelotteryInfoColdDown = updatelotteryInfoRange;
      }
      tempStore.state.lotteryDrawTime = lotteryDrawTime;
      if (updatelotteryInfoColdDown > 0) {
        updatelotteryInfoColdDown--;
      }

      if (checkCountTime()) {
        updateBaseInfoForLucky7daily(tempStore, 'lucky7daily');
      }
    }, 1000);
  },
  changeLottery: () => {
    let tempStore = store;
    let currentLottery = null;
    if (router.history.current.params.name != undefined) {
      currentLottery = router.history.current.params.name;
    } else {
      currentLottery = null;
    }
    updatelotteryInfoColdDown = 0;
    updatelotteryInfo(tempStore, currentLottery);
    updatelotteryInfoColdDown = updatelotteryInfoRange;
    currentLotteryPage = currentLottery;
  },
  getStatus: (lotteryCode) => {
    return store.state.lotteryInfo[lotteryCode];
  },
  getDrawTime: (lotteryCode) => {
    return lotteryDrawTime[lotteryCode];
  },
  getComingDraw: () => {
    if (lotteryDrawTime == null) {
      return [];
    }
    let sortable = [];
    let result = [];
    const trimArray = Object.keys(lotteryDrawTime).reduce((object, key) => {
      // 過濾當即將開獎倒數為0
      if (
        !key.match(
          /^(azxy|sgplhc|jisu|alphaforce|falcon|tesseract|rogue|titan|morningmillions|sunshine)/
        ) &&
        lotteryDrawTime[key] > 0
      ) {
        object[key] = lotteryDrawTime[key];
      }
      return object;
    }, {});

    for (let lottery in trimArray) {
      sortable.push([lottery, lotteryDrawTime[lottery]]);
    }

    let indexOfMax, tempMin;
    let _length = sortable.length <= 3 ? sortable.length : 3;
    for (let t = 0; t < _length; t++) {
      indexOfMax = 0;
      tempMin = sortable[0][1];
      for (let i = 0; i < sortable.length; i++) {
        let lotteryDrawTimeSec = sortable[i][1];
        if (lotteryDrawTimeSec < tempMin) {
          tempMin = lotteryDrawTimeSec;
          indexOfMax = i;
        }
      }
      let itme = sortable.splice(indexOfMax, 1)[0];
      result.push(itme[0]);
    }

    return result;
  },
};

lotteryUtil.init();

export default lotteryUtil;
