<template>
    <div class="top">
        <div class="top_background">
            <div class="marquee">
                <div class="top_content">
                    <div class="notice">
                        <img style="margin-top: 3px;" src="@/img/top_bar-1.svg">
                        <marquee class="info" direction="left">最新公告： 尊敬的用户，彩云资讯为您提供最专业、最优质的彩票信息。立即加入，成就您的梦想。</marquee>
                    </div>
                </div>
            </div>
            
            <div class="header">
                <div class="header_content">
                    <a href="/#/">
                        <img class="logo" src="@/img/logo-1.png">
                    </a>     
                    <div class="menu">
                        <a class="btn" href="/#/">
                            首页
                        </a>
                        <div class="btn" @mouseover="showMenu(1)" @click="switchShowMenu(1)" @mouseleave="resetSwitching()">
                            全国彩
                        </div>
                        <div class="btn" @mouseover="showMenu(2)" @click="switchShowMenu(2)" @mouseleave="resetSwitching()">
                            境外彩
                        </div>
                        <div class="btn" @mouseover="showMenu(3)" @click="switchShowMenu(3)" @mouseleave="resetSwitching()">
                            彩票官网
                        </div>
                        <div class="btn" @mouseover="showMenu(4)" @click="switchShowMenu(4)" @mouseleave="resetSwitching()">
                            图纸图库
                        </div>
        
                        <HeaderMenuSmall v-if="isShowMenu==1" style="top:86px;"  :lotteryList="lotteryList1" @hideMenu="hideMenu"/> 
                        <HeaderMenuSmall v-if="isShowMenu==2" style="top:86px; width: 939px;"  :lotteryList="lotteryList2" @hideMenu="hideMenu"/>
                        <HeaderMenuSmall v-if="isShowMenu==3" style="left:892px; top:86px;"  :lotteryList="lotteryList3" @hideMenu="hideMenu"/>
                        <HeaderMenuSmall v-if="isShowMenu==4" style="left:1063px; top:86px;"  :lotteryList="lotteryList5" @hideMenu="hideMenu"/>
                        

                    </div>
                </div>

            </div>
            <div class="banner" v-show="checkShow">
                <div class="content">
                    <div class="popular_lottery">
                        <PopularLottery  :lotteryList="lotteryList4" />
                    </div>

                    <div class="drawing_soon">
                        <ComingLottery />
                    </div>
                </div>
            </div>
          <div class='backup-domain'>
            <div class='domain-column'>
              备用域名1
              <a href='https://cy1881.net'>
                cy1881.net
              </a>
            </div>
            <div class='domain-column'>
              备用域名2
              <a href='https://cy1882.net'>
                cy1882.net
              </a>
            </div>
          </div>
        </div>
        <div class="bottom" v-show="checkShow">
            <div class="bottom_content">
                <div class="bottom_content_item_row">
                    <div class="bottom_content_item" @click="switchShowBottomMenu(0)" @mouseover="showBottomMenu(0)" @mouseleave="resetSwitching()">
                        <img src="@/img/top_option-1.png">
                    </div>
                    <div class="bottom_content_item" @click="switchShowBottomMenu(1)" @mouseover="showBottomMenu(1)" @mouseleave="resetSwitching()">
                        <img src="@/img/top_option-6.png">
                    </div>
                    <!--<div class="bottom_content_item " @click="switchShowBottomMenu(2)" @mouseover="showBottomMenu(2)" @mouseleave="resetSwitching()">
                        <img src="@/img/top_option-3.png">
                    </div>-->
                    <div class="bottom_content_item" @click="switchShowBottomMenu(3)" @mouseover="showBottomMenu(3)" @mouseleave="resetSwitching()">
                        <img src="@/img/top_option-5.png">                        
                    </div>
                    <HeaderMenu v-if="isShowBottomMenu" style="top:175px; left:180px;" :lotteryList="lotteryList_bottom[bottomMenuIndex]" @hideMenu="hideBottomMenu"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderMenuSmall from '@/components/header/HeaderMenuSmall.vue'
    import HeaderMenu from '@/components/header/HeaderMenu.vue'
    import ComingLottery from '@/components/ComingLottery.vue'   
    import PopularLottery from '@/components/PopularLottery.vue'   
    
    export default {
        name: 'Top',
        props: {
            msg: String
        },
        components: {
            HeaderMenuSmall,
            HeaderMenu,
            ComingLottery,
            PopularLottery
        },
        data() {
            return {
                isShowMenu: 0,
                isShowBottomMenu: false,
                bottomMenuIndex: 0,
                isSwitching: false,
                lotteryList1:[
                    {
                        name: "全国彩",
                        subItem:[
                            {
                                name: "七星彩",
                                url: "/#/lottery/qxc",
                                newImg: false,
                            },
                             {
                                name: "排列三",
                                url: "/#/lottery/pl3",
                                newImg: false,
                            },
                            {
                                name: "排列五",
                                url: "/#/lottery/pl5",
                                newImg: false,
                            },
                            {
                                name: "香港六合彩",
                                url: "/#/lottery/xglhc",
                                newImg: false,
                            },
                            {
                                name: "福彩快8六合彩",
                                url: "/#/lottery/kl8lhc",
                                newImg: true,
                            },
                        ]
                                
                     },
                   
                ],
                lotteryList2:[
                    /* {
                        name: "新加坡六合彩",
                        subItem:[
                            {
                                name: "新加坡六合彩",
                                url: "/#/lottery/sgplhc",
                            }
                        ]
                    },
                    */
                    {
                        name: "英国五分彩",
                        subItem:[
                            {
                                name: "幸运五星彩",
                                url: "/#/lottery/penta5classic",
                                newImg: false,
                            },
                            {
                                name: "英国飞艇",
                                url: "/#/lottery/surfing10classic",
                                newImg: false,
                            }
                        ]
                    },
                    {
                       name: "英国三分彩",
                       subItem:[
                            {
                                name: "英国赛车",
                                url: "/#/lottery/rolling10",
                                newImg: false,
                            },
                            {
                                name: "英国时时彩",
                                url: "/#/lottery/penta5",
                                newImg: false,
                            },
                            {
                                name: "英国快乐彩",
                                url: "/#/lottery/infinity8",
                                newImg: false,
                            },
                            {
                                name: "英国快乐8",
                                url: "/#/lottery/cannon20",
                                newImg: false,
                            },
                            {
                                name: "英国11选5",
                                url: "/#/lottery/lucky5",
                                newImg: false,
                            },
                            {
                                name: "英国快三",
                                url: "/#/lottery/gamma3",
                                newImg: false,
                            },
                        ]
                    },
                    {
                        name: "英國极速彩",
                        subItem:[
                            {
                                name: "英国极速赛车",
                                url: "/#/lottery/rolling10ex",
                                newImg: false,
                            },
                            {
                                name: "英国极速时时彩",
                                url: "/#/lottery/penta5ex",
                                newImg: false,
                            },
                            {
                                name: "英国极速快乐彩",
                                url: "/#/lottery/infinity8ex",
                                newImg: false,
                            },
                            {
                                name: "英国极速快乐8",
                                url: "/#/lottery/cannon20ex",
                                newImg: false,
                            },
                            {
                                name: "英国极速11选5",
                                url: "/#/lottery/lucky5ex",
                                newImg: false,
                            },
                            {
                                name: "英国极速快三",
                                url: "/#/lottery/gamma3ex",
                                newImg: false,
                            }
                        ]
                    },
                    {
                        name: "英国天天彩",
                        subItem:[
                             {
                                name: "英国天天彩",
                                url: "/#/lottery/lucky7daily",
                                newImg: false,
                             },
                             {
                                name: "英国五球彩",
                                url: "/#/lottery/pl5daily",
                                newImg: true,
                             }
                        ]
                    },
                    {
                        name: "澳洲彩",
                        subItem:[
                            {
                                name: "澳洲幸运5",
                                url: "/#/lottery/azxy5",
                                newImg: false,
                            },
                            {
                                name: "澳洲幸运8",
                                url: "/#/lottery/azxy8",
                                newImg: false,
                            },
                            {
                                name: "澳洲幸运10",
                                url: "/#/lottery/azxy10",
                                newImg: false,
                            },
                            {
                                name: "澳洲幸运20",
                                url: "/#/lottery/azxy20",
                                newImg: false,
                            }
                        ]
                    },
                    {
                        name: "极速系列",
                        subItem:[
                            {
                                name: "极速快三",
                                url: "/#/lottery/jisukuai3",
                                newImg: false,
                            },  
                            {
                                name: "极速赛车",
                                url: "/#/lottery/jisusaiche",
                                newImg: false,
                            },
                            {
                                name: "极速时时彩",
                                url: "/#/lottery/jisushishi",
                                newImg: false,
                            },
                            {
                                name: "极速快乐十分",
                                url: "/#/lottery/jisukuaile",
                                newImg: false,
                            },
                        ]
                    }
                ],
                lotteryList3:[
                    {
                        name: "七星彩",
                        url: "https://www.lottery.gov.cn/qxc/index.html",
                        newImg: false,
                    },
                    {
                        name: "排列三",
                        url: "https://www.lottery.gov.cn/plwf/index.html",
                        newImg: false,
                    },
                    {
                        name: "排列五",
                        url: "https://www.lottery.gov.cn/plwf/index.html",
                        newImg: false,
                    },
                    {
                        name: "香港六合彩",
                        url: "https://bet.hkjc.com/marksix/index.aspx?lang=ch",
                        newImg: false,
                    },
                    {
                        name: "福彩快8六合彩",
                        url: "https://www.cwl.gov.cn/ygkj/kjzb/",
                        newImg: false,
                    },
                    {
                        name: "英国天天彩",
                        url: "https://www.uk-wl.co.uk/lottoresult/",
                        newImg: false,
                    },
                

                ],
                lotteryList5:[
                    {
                        name: "香港六合彩",
                        url: "/#/imageslib/xglhc",
                    },
                    {
                        name: "英国天天彩",
                        url: "/#/imageslib/lucky7daily",
                    },
                    {
                        name: "福彩快8六合彩",
                        url: "/#/imageslib/kl8lhc",
                    }
                ],
                ///國家分類
                lotteryList_bottom:[
                    [
                        {
                            name: "七星彩",
                            lotteryCode: "qxc",
                        },
                        {
                            name: "排列三",
                            lotteryCode: "pl3",
                        },
                        {
                            name: "排列五",
                            lotteryCode: "pl5",
                        },
                        {
                            name: "香港六合彩",
                            lotteryCode: "xglhc",
                        },
                        {
                            name: "福彩快8六合彩",
                            lotteryCode: "kl8lhc",
                        },
                    ],
                    [
                        {
                            name: "幸运五星彩",
                            lotteryCode: "penta5classic",
                        },
                        {
                            name: "英国飞艇",
                            lotteryCode: "surfing10classic",
                        },
                        {
                            name: "英国赛车",
                            lotteryCode: "rolling10",
                        },
                        {
                            name: "英国时时彩",
                            lotteryCode: "penta5",
                        },
                        {
                            name: "英国快乐彩",
                            lotteryCode: "infinity8",
                        },
                        {
                            name: "英国快乐8",
                            lotteryCode: "cannon20",
                        },
                        {
                            name: "英国11选5",
                            lotteryCode: "lucky5",
                        },
                        {
                            name: "英国快三",
                            lotteryCode: "gamma3",
                        },
                        {
                            name: "英国极速赛车",
                            lotteryCode: "rolling10ex",
                        },
                        {
                            name: "英国极速时时彩",
                            lotteryCode: "penta5ex",
                        },
                        {
                            name: "英国极速快乐彩",
                            lotteryCode: "infinity8ex",
                        },
                        {
                            name: "英国极速快乐8",
                            lotteryCode: "cannon20ex",
                        },
                        {
                            name: "英国极速11选5",
                            lotteryCode: "lucky5ex",
                        },
                        {
                            name: "英国极速快三",
                            lotteryCode: "gamma3ex",
                        },
                        {
                            name: "英国天天彩",
                            lotteryCode: "lucky7daily",
                        },
                        {
                            name: "英国五球彩",
                            lotteryCode: "pl5daily",
                        },
                      
                    ],
                    [
                        {
                            name: "新加坡六合彩",
                            lotteryCode: "sgplhc",
                        },
                    ],
                    [
                        {
                            name: "澳洲幸运5",
                            lotteryCode: "azxy5",
                        },
                        {
                            name: "澳洲幸运8",
                            lotteryCode: "azxy8",
                        },
                        {
                            name: "澳洲幸运10",
                            lotteryCode: "azxy10",
                        },
                        {
                            name: "澳洲幸运20",
                            lotteryCode: "azxy20",
                        },
                    ]
                ],
                ///熱門彩種
                lotteryList4:[
                    {   code:"xglhc",
                        name: "香港六合彩",
                        url: "/#/lottery/xglhc",
                        isNew: false,
                    },
                    {   code:"kl8lhc",
                        name: "福彩快8六合彩",
                        url: "/#/lottery/kl8lhc",
                        isNew: true,
                    },
                    {   code:"qxc",
                        name: "七星彩",
                        url: "/#/lottery/qxc",
                        isNew: false,
                    },
                    {   code:"pl5",
                        name: "排列五",
                        url: "/#/lottery/pl5",
                        isNew: false,
                    },
                    {   code:"pl3",
                        name: "排列三",
                        url: "/#/lottery/pl3",
                        isNew: false,
                    },
                    {   code:"penta5classic",
                        name: "幸运五星彩",
                        url: "/#/lottery/penta5classic",
                        isNew: false,
                    },
                ]
            }
        },
        methods: {
            resetSwitching() {
                this.isSwitching = false;
            },
            showMenu (i) {
                this.isShowMenu = i;
            },
            hideMenu() {
                if(!this.isSwitching) {
                    this.isShowMenu = 0;
                }                
            },
            switchShowMenu(i) {
                if(this.isShowMenu == 0) {
                    this.isShowMenu = i;
                } else {
                    this.isShowMenu = 0;
                } 
                this.isSwitching = true;
            },
            showBottomMenu (i) {
                this.bottomMenuIndex = i;
                this.isShowBottomMenu = true;                
            },
            hideBottomMenu() {
                if(!this.isSwitching) {
                    this.isShowBottomMenu = false;
                }                
            },
            switchShowBottomMenu(i) {
                this.bottomMenuIndex = i;
                this.isShowBottomMenu = ! this.isShowBottomMenu;  
                this.isSwitching = true;             
            }
        },
        computed: {
            checkShow:  function() {
                return (this.$route.name !='Home' ) ? false : true;
            }
            
        },
        
    }
</script>


<style scoped lang="scss">
.top{
    .top_background{
        background-color: #ffffff;
        .marquee {
            width: 100%;
            height: 36px;
            background-color: #ebebeb;;
            border: unset;
            font-size: 16px;
            font-weight: bold;
            line-height: 34px;
            color: #000000;
            .top_content {
                width: 1200px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                .notice {
                    display: flex;
                    .info {
                        width: 1160px;
                        margin-left: 10px;
                    }
                }
            }
        }

        .header{
            height: 120px;
            width: 1202px;
            margin: auto;
            position: relative;
            .header_content {
                display: flex;
                justify-content: space-between;
                padding-top: 30px;
                .logo{
                    width: 342px;
                    height: 59.8px;
                }
                
                .menu{
                    width: 820px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn{ 
                        cursor: pointer;
                        width: 140px;
                        height: 40px;
                        border-radius: 6px;
                        background-color: #ff6819;
                        color: white;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 40px;
                        position: relative;
                        &::after{
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 4px 4px 0 4px;
                            border-color: #ffffff transparent transparent transparent;
                            display: inline-block;
                            vertical-align: middle;
                        }
                        &:nth-child(1)::after{
                            display: none;
                        }
                    }
                }
            }
            .lottery_menu{
                position: absolute;
                left: 0px;
                top: calc(226px - 68px);
                width: 372px;
                z-index: 1;
                .title{                
                    height: 68px;
                    border-radius: 5px 5px 0 0;
                    background-color: #0f2957;
                    color: #ff6819;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 68px;
                    
                }
                .lottery_menu_content{
                    height: auto;
                    padding-bottom: 40px;
                    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.35);
                    background-image: linear-gradient(to bottom, rgba(12, 28, 57, 0.9), rgba(15, 41, 87, 0.9));
                    .row{
                        height: auto;
                        padding-top: 25px;
                        .type{
                            color: #f0f6ff;
                            text-align: left;
                            font-size: 17px;
                            font-weight: 600;
                            
                        }
                        .itme_row{
                            display: flex;
                            flex-direction: row;
                            flex-flow: wrap;
                            padding-top: 16px;
                            margin-left: 50px;
                            .itme{
                                font-size: 14px;
                                font-weight: 300px;
                                text-align: left;
                                color: #fff;
                                margin-right: 14px;
                                margin-top: 14px;

                            }
                        }                    
                    }
                    .row:first-child{
                        padding-top: 35px;
                    }
                   
                    .menuType1{
                        width: 88px;
                        height: 40px;
                        text-align: center;
                        border-radius: 5px;
                        background-color: #ff6819;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        display: -webkit-flex;
                        -webkit-align-items: center;
                        font-size:20px;
                        color:#fff;
                    
                    }
                    .menuType2{
                        font-size:16px;
                        color:#ffed74;
                        text-align: left;
                        padding-left: 32px;
                    }
                }
            }
        }
        .banner{
            width: 100%;
            height: 470px;
            background-image: url('~@/img/bn.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            .content{
                width: 1200px;
                margin: auto;
                position: relative;
                .slogan{
                    position: absolute;
                  right: 468px;
                  top: 188px;
                    text-align: left;
                    color: #f9d165;
                    font-size: 36px;
                    font-weight: bold;
                    p{
                        padding-bottom: 37px;
                    }
                }
                .drawing_soon{
                    position: absolute;
                    right: -10px;
                    top: 4px;
                    width: 320px;
                    height: auto;
                }
                .popular_lottery{
                    position: absolute;
                    width: 320px;
                    height: auto;
                    top: 4px;
                }
            }        
        }
    }
    .bottom{
        .bottom_content{
            position: relative;
            height: 100%;
            width: 1280px;
            margin: auto; 
            z-index: 2;       
            .bottom_content_item_row{
                padding: 0 105px;
                margin-top: 40px;
                bottom: 72px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 50px;
                .bottom_content_item{
                    margin-right: 0.08rem;
                    z-index: 122;
                    cursor: pointer;
                    position: relative;
                    img{
                        width: 270px;
                        height: 200px;
                    }
                    div{
                        top: 0.87rem;
                        left: 0.65rem;
                        position: absolute;
                        p{
                            font-family: 'Microsoft Yahei';
                            font-size: 30px;
                            font-weight: 300;
                            text-align: center;
                            color: #0f2957;
                        }
                    }
                }
            }
        }
    }
}
.backup-domain{
  margin-top:2px;
  background:#fe6918;
  color:#fcff00;
  display:flex;
  justify-content: center;
  height:33px;
  align-items: center;
  font-style: italic;
  font-weight:bold;
  .domain-column{
    position:relative;
    padding:10px;
    margin:0 5px;
    &:first-child:after{
      position:absolute;
      content:'';
      display:block;
      top:6px;
      width:1px;
      height:24px;
      background:#ffb58c;
      right:0;
      z-index:1;
    }
  }
  a{color:#fff;text-decoration: underline;padding:5px 14px;}
  @media(min-width:992px){
    height:36px;
    font-size:18px;
    .domain-column{
      padding:10px 6px;
    }
  }
}
</style>