import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

let vuex = new Vuex.Store({
    state: {
      lotteryInfo: {
        
      },
      lotteryDrawTime: {
      },
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})

export default vuex