<template>
    <div class="lottery_coming_card">
        
        <div class="info">
            <div class="top">
                <div class="icon_and_title">
                    <a :href="`/#/lottery/${lotteryCode}`">
                        <img class="icon" :src="require(`@/img/ballicon/${lotteryCode}_icon.png`)">
                    </a>
                    <a class="lottery_info" :href="`/#/lottery/${lotteryCode}`">
                        <div class="lottery_name">
                            {{lotteryName}}
                        </div>
                        <div class="draw_period">
                            {{currentPeriod | formatComingLotteryWithDate(currentDrawTime, lotteryCode, currentPeriod)}}
                        </div>
                    </a>
                </div>
                <div class="timer">
                    <div class="period">
                        {{nextPeriod | formatComingLottery(nextDrawTime, lotteryCode, nextPeriod)}}
                    </div>
                    <div class="value" v-if="lotteryConfig[lotteryCode].lotteryType==1">{{lotteryDrawTime[lotteryCode] | reciprocalDay}}<span>天</span>{{lotteryDrawTime[lotteryCode] | reciprocalHour}}<span>时</span>{{lotteryDrawTime[lotteryCode] | reciprocalMinute}}<span>分</span>{{lotteryDrawTime[lotteryCode] | reciprocalSecond}}<span>秒</span>
                    </div>
                     <div class="value" v-else>{{lotteryDrawTime[lotteryCode] | reciprocalMinute}}<span>分</span>{{lotteryDrawTime[lotteryCode] | reciprocalSecond}}<span>秒</span>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="draw_number">
                        <div v-for="(item, index) in currentDraw" 
                            :key="'currentDraw' + index" 
                            :class="`${lotteryCode} ball ball-${getIntNumStyle(item)}`">
                            <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                            <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(item)}}</span>
                            <span v-else>
                                {{item}}
                            </span>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import {lotteryConfig} from "@/assets/lotteryConfig.js";

    export default {
        name: 'LotteryCard_type1',
        props: {
            lotteryCode: String,
        },
        data() {
            return {
                timerCountDown: 0,
                lotteryName: "",
                nextPeriod: "",
                currentDraw: [1,2,3,4,5,6,7],
                currentDrawTime: "",
                currentPeriod: "",
                nextDrawTime: "",
                lotteryConfig: lotteryConfig,
                padLeftLottery: ['azxy8','jisukuaile','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'hklhc'],
                LotteryTwenty:['cannon20','azxy20','cannon20ex'],
                padLeftLottery2: ['azxy10','azxy20','jisusaiche']
            }
        },
        computed: {
            ...mapState([
                "lotteryInfo",
                "lotteryDrawTime"
            ]),
	    },
        watch: {
            lotteryDrawTime: {
                handler: function(value){
                    let time = value[this.lotteryCode];
                    if(time)
                    this.timerCountDown = time;
                },
                immediate: true,
                deep: true,
            },
            lotteryInfo: {
                handler: function(value){
                    this.setLotteryInfo(this.lotteryCode);                   
                },
                immediate: true,
                deep: true,
            },
        },
        methods: {
            setLotteryInfo: function(lotteryCode) {

                if(!this.lotteryInfo[lotteryCode]) {
                    return;
                }
                let tempInfo = this.lotteryInfo[lotteryCode];
                let currentData = tempInfo.current_data;
                let nextData = tempInfo.next_data;
                //傳值
                this.lotteryName = tempInfo.name;
                this.currentPeriod = currentData.period_no;
                this.nextPeriod = nextData.period_no;
                this.nextDrawTime = nextData.draw_time;
                this.currentDraw = currentData.draw.split(","); 
                this.currentDrawTime = currentData.draw_time;                   
            },
            getIntNumStyle(num){
                return parseInt(num)
            }
        },
        mounted() {
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .lottery_coming_card {
        padding: 10px 0px 16px 10px;
        display: flex;
        background-color: #ffffff;    
        box-shadow:inset 0px 15px 10px -15px rgba(0, 0, 0, 0.3);
        height: auto;
        box-sizing: border-box;
        position: relative;
        z-index: 3;
        height: 134px;
        .info {
            width: 100%;
            height: 100%;

            .top {
                display: flex;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                justify-content: space-between;
                .icon_and_title{
                    display: flex;
                    align-items: center;
                    display: -webkit-flex;
                    -webkit-align-items: center;
                    .icon {
                        width: 50px;
                        height: 50px;
                        margin-right: 0.05rem;
                    }
                    .lottery_info {
                        width: 110px;
                        box-sizing: border-box;
                        .lottery_name {
                            font-size: 15px;
                            font-weight: 500;
                            text-align: left;
                            color: #061839;
                        }

                        .draw_period {
                            width: 180px;
                            font-family: "Open Sans";
                            font-size: 22px;
                            font-weight: 500;
                            text-align: left;
                            color: #596c81;
                            transform: scale(0.5);
                            transform-origin: center left;
                        }
                    }
                }

                .timer {
                    transform: scale(0.5);
                    transform-origin: center left;
                    font-size: 34px;
                    
                    .period{          
                        font-size: 22px;
                        font-weight: 400;
                        text-align: left;
                        color: #4a545f;
                        padding-bottom: .06rem;
                    }

                    .value {
                        letter-spacing:2px;
                        width: 270px;
                        height: 17px;
                        font-family: "Open Sans";
                        font-weight: 300;
                        text-align: left;
                        color: #4a545f;
                        display: inline-block;
                        
                        span{
                            font-weight: 400;
                            font-size: 17px;
                        }
                    }
                }

            }

            .bottom {
                margin: 3px 0 0 0;
                .draw_number {
                    display: inline-grid;
                    grid-template-columns: repeat(11, 1fr);
                    width: auto;
                    max-width: 300px;
                    .ball {
                        @include ballSize(.24rem);   
                        font-size: .16rem;
                        margin-right: .03rem;
                        margin-bottom: .02rem;
                    }
                    .ball:nth-child(10) {
                        grid-column: span 2;
                    }
                    .ball{
                        grid-column: span 1;
                                    
                    }
                }
            }
        }
    }
    .lottery_coming_card:first-child{
        box-shadow: unset;
    }
    .lottery_coming_card:last-child{
        border-radius: 0 0 10px 10px;
    }
</style>