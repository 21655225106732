import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/main.scss'
import lotteryUtil from './utils/lotteryStatus.js'
import {lotteryConfig} from './assets/lotteryConfig.js'
import * as filters from './filters';

Vue.config.productionTip = false
Vue.prototype._lotteryUtil = lotteryUtil;
Vue.prototype._lotteryConfig = lotteryConfig;


// 引入vue2-svg-icon并且注册组件
import Icon from 'vue2-svg-icon/Icon'
Vue.component('icon',Icon);

// 全局註冊 Paginate組件
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

// 註冊全局 filter
Object.keys(filters).forEach(key => {  
	Vue.filter(key, filters[key])  
})
// filter
Object.filter = (mainObject, filterFunction)=>
    Object.keys(mainObject)
          .filter( (ObjectKey)=>filterFunction(mainObject[ObjectKey]))
          .reduce( (result, ObjectKey)=> ( result[ObjectKey] = mainObject[ObjectKey], result ), {} );

//Date  Safarie格式統一
// Date = function (Date) {
//   MyDate.prototype = Date.prototype;
//   return MyDate;

//   function MyDate() {
//     // 当只有一个参数并且参数类型是字符串时，把字符串中的-替换为/
//     if (arguments.length === 1) {
//       let arg = arguments[0];
//       if (Object.prototype.toString.call(arg) === '[object String]' && arg.indexOf('T') === -1) {
//         arguments[0] = arg.replace(/-/g, "/");
//         // console.log(arguments[0]);
//       }
//     }
//     let bind = Function.bind;
//     let unbind = bind.bind(bind);
//     return new (unbind(Date, null).apply(null, arguments));
//   }
// }(Date);

// VueGtm 嵌入
/*import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
  id: "GTM-WRDS8QX", 
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    // gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    // gtm_preview: "env-4",
    // gtm_cookies_win: "x",
  },
  // defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  // enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  // debug: true, // Whether or not display console logs debugs (optional)
  // loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});*/

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
