<template>
  <div class="coming_lottery">
        <div class="title">
             <img class="icon" :src="require(`@/img/icon/award-symbol.png`)">
            即将开奖
        </div>
        <div class="body">
            <LotteryCardType1 v-for="(item, index) in comingLottery" :key="'coming'+index" :lotteryCode="item" />
        </div>        
  </div>
</template>

<script>
import LotteryCardType1 from '@/components/lottery_coming_card/Lottery_Coming_Card.vue'
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    name: 'ComingLottery',
    props: {
    },
    components: {
        LotteryCardType1,
    },
    computed: {
        ...mapState([
            "lotteryInfo",
            "lotteryDrawTime"
        ]),
    },
    watch: {
        lotteryInfo: {
            handler: function(value){
                this.reflashLotteryCome();
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            comingLottery: [
                /*"xglhc", "sgplhc","pl5","pl3","qxc","powerball", "megamillions"*/
            ],
        }
    },
    methods: {
        reflashLotteryCome () {

            this.comingLottery = this._lotteryUtil.getComingDraw();
            
        },
    },
    mounted() {
        this.reflashLotteryCome();
    }
}
</script>

<style scoped lang="scss">

.coming_lottery{
    width: 100%;
    height: 100%;

    @media (max-width: $mobile_width) {
        width: 100%;
    }

    .title {
        width: 320px;
        height: 60px;
        border-radius: 10px 10px 0 0;
        background-color: #ed332a;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        .icon{
             width: 30px;
            height: 30px;
            margin: 0 10px;
        }
    }
    .body{
        background-color: #ffffff;
        width: 100%;
        height: auto;
        border-radius: 0 0 10px 10px;
    }
    .lotteryCard_type1:last-child {
        border-bottom: unset;
    }
}

</style>
