<template>
    <div class="footer">
        <div class="footer_main">
            <div class="footer_main_content">
                <div class="left">
                    <a href="/">
                        <div class="logo">
                            <img src="@/img/footer_logo.png">
                        </div>
                    </a>
                </div>
                <div class="right">
                    <div class="row">
                        <a class="title" href="/">首页</a>
                        <a class="title" href="/#/about" >关于我们</a>
                        <a class="title" href="/#/disclaimer" >免责声明</a>
                        <a class="title" href="/#/gamerule" style='margin-bottom:50px;' >玩法规则</a>
                        <a class="title" href="" >彩票官网</a>
                        <a class="btn" href="https://www.lottery.gov.cn/qxc/index.html" target="_blank" rel="noreferrer noopener">
                            七星彩
                        </a>
                        <a class="btn" href="https://www.lottery.gov.cn/plwf/index.html" target="_blank" rel="noreferrer noopener">
                            排列三
                        </a>
                        <a class="btn" href="https://www.lottery.gov.cn/plwf/index.html" target="_blank" rel="noreferrer noopener">
                            排列五
                        </a>
                        <a class="btn" href="https://bet.hkjc.com/marksix/index.aspx?lang=ch" target="_blank" rel="noreferrer noopener">
                            香港六合彩
                        </a>
                      <a class="btn" href="https://www.cwl.gov.cn/ygkj/kjzb/" target="_blank" rel="noreferrer noopener">
                        福彩快8六合彩
                      </a>
                        <a class="btn" href="https://www.uk-wl.co.uk/lottoresult/" target="_blank" rel="noreferrer noopener">
                            英国天天彩
                        </a>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="copy_right">
            Ⓒ Copyright 2021 www.lottos.cloud  All rights reserved 彩云资讯 版权所有
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        props: {
            msg: String
        },
        data() {
            return {
            }
        },
        components: {
        },
        methods: {
        },
    }
</script>


<style scoped lang="scss">
.footer{
    .footer_main{
        width: 100%;
        height: 310px;
        background-color: #071633;
        .footer_main_content{
            position: relative;
            height: 100%;
            width: $inner;
            margin: auto;
            box-sizing: border-box;
            padding: 40px 2.6rem 20px 0.6rem;
            display: flex;
            justify-content: stretch;
            .left{
                display: flex;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                height: 100%;
                margin-right: 200px;
                margin-top: -25px;
                .logo{
                    width: 3.83rem;
                    img{
                        width: 100%;
                    }
                }
            }
            .right{
                width: 100%;
                height: 100%;
                position: relative;
                .row{
                        display: flex;
                        flex-wrap: wrap;
                        height: 260px;
                        flex-direction: column;
                    .title{
                        font-weight: bold;
                        font-size: 18px;
                        width: 1.4rem;
                        margin: 10px 0;
                        color: #ffffff;
                        text-align: left;
                        line-height: 25px;
                    }
                    .title:nth-child(1):before{
                        content: "";
                        display: block;
                        background: url("~@/img/home.png") no-repeat;
                        background-size: cover;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin: 0 15px 0 0;
                    }
                    .title:nth-child(2):before{
                        content: "";
                        display: block;
                        background: url("~@/img/about.png") no-repeat;
                        background-size: cover;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin: 0 15px 0 0;
                    } 
                    .title:nth-child(3):before{
                        content: "";
                        display: block;
                        background: url("~@/img/disclaimer.png") no-repeat;
                        background-size: cover;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin: 0 15px 0 0;
                    }  
                    .title:nth-child(4):before{
                        content: "";
                        display: block;
                        background: url("~@/img/rules.png") no-repeat;
                        background-size: cover;
                        width: 25px;
                        height: 25px;
                        float: left;
                        margin: 0 15px 0 0;
                    }
                    .title:nth-child(5):before{
                        content: "";
                        display: block;
                        background: url("~@/img/global.png") no-repeat;
                        background-size: cover;
                        width: 30px;
                        height: 30px;
                        float: left;
                        margin: 0 25px 0 0;
                    }
                    .btn{
                        font-size: 18px;
                        font-weight: 800;
                        font-stretch: normal;
                        text-align: left;
                        color: #a0abc1;
                        margin-right: 0.4rem;
                        margin-bottom: 8px;
                        margin-top: 8px;
                        padding-left: 54px;
                    }
                }
                .row:nth-child(4){
                    margin-bottom: 0.16rem;
                }
                .bottom_row{
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    a{
                        margin-right: 0.5rem;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;
                        color: #a0abc1;
                    }
                }
            }
        }
    }
    .copy_right{
        display: flex;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px;
        background-color: #122953;
        color: #757e8d;
        font-family: 'Microsoft Yahei';
        font-size: 12px;
    }
}
</style>