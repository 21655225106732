<template>
    <div class="header_menu" v-if="isShow" @mouseleave="hideMenu">
        <div class="content">
            <div v-for="(item,index) in lotteryList" :key="index" class="menu_item">         
                <a :href="`/#/lottery/${item.lotteryCode}`">
                    <img class="icon" :src="require(`@/img/ballicon/${item.lotteryCode}_icon.png`)">
                    <div>
                        <p>{{item.name}}</p>
                        <p v-if="lotteryInfo && lotteryInfo[item.lotteryCode] != undefined">
                            {{lotteryInfo[item.lotteryCode].current_data.period_no}}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import {lotteryConfig} from "@/assets/lotteryConfig.js";

    export default {
        name: 'HeaderMenu',
        props: {
            msg: String,
            lotteryList: {
                type: Array,
                default: []
            },
        },
        data() {
            return {
                isShow: true,
                mouseEvent: null,
            }
        },
        components: {
        },
        computed: {
            ...mapState([
                "lotteryInfo",
                "lotteryDrawTime"
            ]),
	    },
        methods: {
            showMenu () {
                this.isShow = true;
            },
            hideMenu () {
                this.$emit("hideMenu");
            },
            mouseEventHide (e) {
                this.$nextTick(() => {
                    this.$emit("hideMenu");
                }); 
            }
        },
        mounted() {    
            document.addEventListener("click", this.mouseEventHide, false);
        },
        destroyed() {
            document.removeEventListener("click", this.mouseEventHide, false);
        }
    }
</script>
<style scoped lang="scss">
    .header_menu {
        position: absolute;
        z-index: 1;
        //background-image: linear-gradient(to bottom, #0e2e6a, #183b80 9%, #1e4080 16%, #112a5d 46%, #061939 84%, #00194a);
        .content {
            width: 896px;
            height: auto;
            padding: 30px 40px 20px 40px;
            border-radius: 12px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: rgba(255, 255, 255, 0.9);
            box-sizing: border-box;
            display: flex;
            flex-flow: wrap;
            justify-content: flex-start;
            .menu_item{
                display: flex;
                margin: 13px 0;
                width: 195px;
                a{
                    display: flex;
                    .icon{
                        width: 0.5rem;
                        height: 0.5rem;
                        margin-right: 0.05rem;
                    }
                    div{
                        height: 0.5rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        p{
                            font-size: 17px;
                            font-weight: 500;
                            text-align: left;
                            color: #061839;
                            line-height: 0.18rem;
                            text-decoration: none;
                        }
                        p:last-child{
                            font-size: 11px;
                            font-weight: 300;
                            color: #596c81;
                        }
                    }                    
                }                
            }
            .menu_item:hover{
                 p{
                    color: #FF6819;
                    text-decoration: underline;
                }
                 p:last-child{
                    color: #FF6819;
                 }
            }
        }
        
    }
</style>