<template>
  <div class="popular_lottery">
            <div class="title">
                <img class="icon" :src="require(`@/img/icon/fire.png`)">
                 热门彩种
            </div>
            <div class="body">
            <div class="lottery_popular_card" v-for="(item, index) in lotteryList">
                <div class="info">
                    <a :href="`/#/lottery/${item.code}`">
                                    <div class="lottery_info">
                                        <img class="icon" :src="require(`@/img/ballicon/${item.code}_icon.png`)">
                                        <div class="lottery_name">
                                                {{item.name}}
                                        </div>
                                    </div>
                                    <template v-if="!item.isNew">
                                        <img class="hot_icon" :src="require(`@/img/icon/hot.png`)">
                                    </template>
                                    <template v-else>
                                        <img class="new_icon" :src="require(`@/img/icon/new.png`)">
                                    </template>
                                  
                    </a>
                
                </div>
            </div>
        </div>
        </div>        
  </div>
</template>
<script>

export default {
    name: 'lotteryHomeCard',
    props: {
        lotteryList: Array,
    },
   

}

</script>

<style scoped lang="scss">

.popular_lottery{
    width: 100%;
    height: 100%;

    @media (max-width: $mobile_width) {
        width: 100%;
    }

    .title {
        width: 320px;
        height: 60px;
        border-radius: 10px 10px 0 0;
        background-color: #ed332a;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        .icon{
            width: 30px;
            height: 30px;
            margin: 0 10px;
        }
    }
    .body{
        background-color: rgba(255, 255, 255, 0.9);
        width: 100%;
        height: auto;
        border-radius: 0 0 10px 10px;
        
    }
    
}

    .lottery_popular_card {
        padding: 8px 18px;
        display: flex;
        height: auto;
        box-sizing: border-box;
        position: relative;
        z-index: 3;
        .info {
            width: 100%;
            height: 100%;              
                    a{
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;
                        display: -webkit-flex;
                        -webkit-align-items: center;
                     
                    }
                    .icon {
                        width: 50px;
                        height: 50px;
                        margin-right: 0.05rem;
                    }
                    .lottery_info {
                        display: flex;
                        align-items: center;
                        display: -webkit-flex;
                        -webkit-align-items: center;
                        margin-left: 12px;
                        box-sizing: border-box;
                        .lottery_name {
                            font-size: 18px;
                            font-weight: 800;
                            margin: 0 10px 0 20px;
                            text-align: left;
                            color: #4a4a4a;
                        }

                    }
        }
    }
    .lottery_popular_card:not(:last-child):before{
        content: "";
        display: block;
        width: 285px;
        border-bottom: 1px solid #cacaca;
        position: absolute;
        bottom: 0;
    }
    .lottery_popular_card:last-child{
        border-radius: 0 0 10px 10px;
    }
    .lottery_popular_card:first-child{
        box-shadow: unset;
    }

</style>
